import Head from "next/head";
import {Fragment, useContext} from "react";
import {HeaderSix as Header} from "@components/header";
import {FooterOne as Footer} from "@components/footer";
import {ProductsContext} from "@global/ProductsContext";
import {HomePagesNavData as navContent} from "@data/navbar";
import {ServicesOne as Services} from "@components/services";
import {CategoriesTwo as Categories} from "@components/categories";
import {ContentWrapperOne as ContentWrapper} from "@components/wrapper";
import LookbookProducts from "@components/products/LookbookProducts";
import {PromoBannerFoods as PromoBanners} from "@components/promo-banners";
import Script from "next/script";

const HomeDefinitive = () => {
    const {products} = useContext(ProductsContext);

    const logo = "/assets/images/brand/nobg-logo.svg";

    return (
        <Fragment>
            <Head>
                <title>San Marino</title>
            </Head>
            <Script src="https://sdk.mercadopago.com/js/v2"></Script>
            <Header
                logo={logo}
                navbar={true}
                navData={navContent}
                containerFluid={true}
                logoAlignment="left"
                navbarAlignment="left"
            />
            <PromoBanners/>
            <ContentWrapper>
                <Categories/>
                <LookbookProducts className="nomargin"/>
                {/* <Products products={products} /> */}
                <Services/>
            </ContentWrapper>
            <Footer logo={logo} dark={false} newsletter={false}/>
        </Fragment>
    );
};
export default HomeDefinitive;
