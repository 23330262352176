import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "react-bootstrap";
import {useQuery} from "@apollo/client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import router from "next/router"
import promotionQuery from "src/graphql/query/promotionQuery";

const promotionsGql = promotionQuery;

const PromoBanner = () => {

    const {loading, error, data} = useQuery(promotionsGql);
    const [slidesToShow, setSlidesToShow] = useState(3);
    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setSlidesToShow(1);
            } else {
                setSlidesToShow(3);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const renderProductBox = (product) => (
        <div key={product.id} className="tt-col-item">
            <div className="tt-product thumbprod-center">
                <div className="tt-image-box">
                    {product.attributes.images &&
                        product.attributes.images.data.length > 0 && (
                            <div className="product-container text-center d-flex justify-content-center">
                                <img style={{
                                    objectFit: "contain",
                                    backgroundColor: "#ffffff",
                                    width: "200px",
                                    height: "250px",
                                }}
                                     className="img-fluid product-image"
                                     src={process.env.NEXT_PUBLIC_BASE_URL + product.attributes.images.data[0].attributes.url}
                                     alt={product.attributes.title}/>
                            </div>)}
                </div>
                <div>
                    <h2 className="tt-title h5">{product.attributes.title}</h2>
                    <button style={{marginBottom: "55px"}} className="btn"
                            onClick={() => router.push(`/product/${product.id}`)}>Ver producto
                    </button>
                </div>
            </div>
        </div>
    );

    if (error) {
        console.error(error);
        return null;
    }
    if (loading || !data.promotions.data.length) return null;

    const promotionData = data.promotions.data[0];
    const products = promotionData.attributes.products.data;

    const bannerItems =
        promotionData.attributes.imagebanner &&
        promotionData.attributes.imagebanner.data.map((banner, index) => (
            <div key={index} className="tt-promo-fullwidth">
                <img src={process.env.NEXT_PUBLIC_BASE_URL + banner.attributes.url} alt={`banner-${index}`}/>
            </div>
        ));


    const sliderSettings = {
        dots: true,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        vertical: false,
        centerMode: true,
        centerPadding: "0",
        autoplay: true,
        autoplaySpeed: 3000,
    };
    const sliderSettingsImageBanner = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        centerMode: true,
        centerPadding: "0",
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <Container fluid={true}>
            <Row className="tt-carousel-banners" style={{margin: "1px"}}>
                <Col xs={12}>
                    <Slider {...sliderSettingsImageBanner}>{bannerItems}</Slider>
                </Col>
            </Row>
            <h2 style={{marginTop: "50px"}} className="text-center">
                Productos recomendados
            </h2>
            <Row className="tt-carousel-products" style={{margin: "20px"}}>
                <Col xs={12}>
                    <Slider {...sliderSettings}>{products.map((product) => renderProductBox(product))}</Slider>
                </Col>
            </Row>
        </Container>
    );
};

PromoBanner.propTypes = {
    className: PropTypes.string,
};

export default PromoBanner;